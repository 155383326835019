<template>
  <Container>
    <b-row align-h="center">
      <b-col md="4">
        <Card padding="medium">
          <Margins>
            <h1 class="h3 text-center">{{ $t('REGISTRATION') }}</h1>

            <form @submit="handleSubmit" class="margins__double">
              <Margins>
                <InputField
                  :label="`${$t('FORM.PASSWORD')}`"
                  name="password"
                  type="password"
                  autocomplete="new-password"
                  v-model.trim="formData.password"
                  :error="errors.first('password')"
                  v-validate="{ required: true, min: 8 }"
                  ref="password"
                  :data-vv-as="$t('FORM.VALIDATOR.PASSWORD')"
                />
                <InputField
                  :label="`${$t('FORM.PASSWORD_CONFIRMATION')}`"
                  name="passwordConfirmation"
                  type="password"
                  autocomplete="new-password"
                  v-model.trim="formData.passwordConfirmation"
                  :error="errors.first('passwordConfirmation')"
                  v-validate="{ required: true, confirmed: 'password' }"
                  :data-vv-as="$t('FORM.VALIDATOR.PASSWORD')"
                />

                <div class="text-center margins__double">
                  <Button type="submit">{{ $t('CONTINUE') }}</Button>
                </div>
              </Margins>
            </form>
          </Margins>
        </Card>
      </b-col>
    </b-row>
  </Container>
</template>

<script>
import { Card, Container, InputField, Margins, Button } from '@/components';
import { constants } from '@/mixins';
import { ACCEPT_INVITATION } from '@/types';
import { mapActions } from 'vuex';

export default {
  name: 'BackOfficeRegistration',
  mixins: [constants],
  props: {
    invitationId: { type: String },
  },
  data() {
    return {
      formData: {},
    };
  },
  components: {
    InputField,
    Container,
    Card,
    Margins,
    Button,
  },
  methods: {
    ...mapActions([ACCEPT_INVITATION]),
    async handleSubmit(e) {
      e.preventDefault();

      const valid = await this.$validator.validateAll();

      if (!valid) {
        return false;
      }

      const redirectLink = `<a href="${window.location.origin}${
        this.ROUTES.BACK_OFFICE_LOGIN.path
      }">${this.$t('HERE').toLowerCase()}</a>`;
      const notification = this.$t('REGISTRATION_SUCCESS', [redirectLink]);

      await this.ACCEPT_INVITATION({
        invitationId: this.invitationId,
        formData: this.formData,
        notification: notification,
      });
    },
  },
};
</script>
